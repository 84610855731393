body {
  max-width: 100%;
  overflow-x: hidden;
  margin: 0;
}

body {
  margin: 0;
  font-family: 'Noto Sans JP', sans-serif !important;
}
